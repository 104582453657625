<script setup>
import { ref } from 'vue'
import { onMounted } from 'vue'
import Bar from '@/components/inf/variables/helpers/Bar.vue'
import Intro from '@/components/inf/variables/Intro.vue'
import Intro1_2 from '@/components/inf/variables/Intro1_2.vue'
import Name from '@/components/inf/variables/Name.vue'
import Tutorial1 from '@/components/inf/variables/Assignment1/Tutorial1.vue'
import Assignment1 from '@/components/inf/variables/Assignment1/Assignment1.vue'
import Appendix1 from '@/components/inf/variables/Assignment1/Appendix1.vue'
import Tutorial2 from '@/components/inf/variables/Assignment2/Tutorial2.vue'
import Assignment2 from '@/components/inf/variables/Assignment2/Assignment2.vue'
import Appendix2 from '@/components/inf/variables/Assignment2/Appendix2.vue'
import * as scrollHelper from "@/helpers/scroll-helper.js"
import router from "@/router"
import store from "@/store"
import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js"

onMounted(() => {
  scrollHelper.scrollToTop();
});

const currentTab = ref(0)
const tabs = [Intro, Intro1_2, Name, Tutorial1, Assignment1, Appendix1, Tutorial2, Assignment2, Appendix2]
const next = ref(true)

function forwardBtnClicked() {
  currentTab.value++
}
function backBtnClicked() {
  enable()
  currentTab.value--
}
async function finishBtnClicked() {
  const myOSA = await myOSAhandler.getMyInformatikOSA(
  store.getters.getUser.username
  )
  //set securityFinished=true in backend
  await myOSAhandler.finishModule(myOSA.id, { variablesFinished: true })
  router.push("/informatik")
}
function disable() {
  next.value = false
}
function enable() {
  next.value = true
}
</script>

<template>
<div class = 'container'>
  <Bar
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    />
  <component @disable-weiter="disable" @enable-weiter="enable" :is = 'tabs[currentTab]'/>
  <div class="custom-btn" @click = 'forwardBtnClicked' v-if = 'next && currentTab < tabs.length - 1 ? true : false'>Weiter</div>
  <div class="custom-btn" @click = 'finishBtnClicked' v-if = 'currentTab == tabs.length - 1'>Modul beenden</div>
  <div class="custom-btn-secondary" @click = 'backBtnClicked' v-if = 'currentTab > 0 ? true : false'>Zurück</div>
</div>
</template>
<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}</style>