<script setup>
import { ref } from 'vue'
import VariableBox from '@/components/inf/variables/helpers/VariableBox.vue'

const image = ref(require("@/assets/inf/variable/restart.png"))

const feedback = ref("")

const jars = ref([
    {id: 0, name: "P", val: "Salz"},
    {id: 1, name: "S", val: "Oregano"},
    {id: 2, name: "O", val: "Pfeffer"},
  ])

const counter = ref({
    Salz: 1,
    Oregano: 1,
    Pfeffer: 1
  })

const startDrag = (event, jar) => {
  feedback.value = ""
  event.dataTransfer.dropEffect = "move"
  event.dataTransfer.effectAllowed = "move"
  event.dataTransfer.setData("val", jar.val)
}

const onDrop = (event, id) => {
  const val = event.dataTransfer.getData("val")
  const dropVal = jars.value[id].val
  let validMove = true
  if(dropVal != "Leer") {
    if(counter.value[dropVal] - 1 < 1){
      getFeedback(dropVal)
      //validMove = false
    } 
  }
  if(validMove){
    counter.value[dropVal] -= 1
    counter.value[val] += 1
    jars.value[id].val = val
    }
}

function reset(){
  jars.value = [
    {id: 0, name: "P", val: "Salz"},
    {id: 1, name: "S", val: "Oregano"},
    {id: 2, name: "O", val: "Pfeffer"},
  ]
}

const getFeedback = (val) => {
  feedback.value = val
}
</script>
<template>
  <div class = 'container'>
    <div class="row animate glow delay-1">
      <h1>Tutorial 2: Neue Werte zuweisen</h1>
      <p>
        Die unter Ihnen, die minimale Lateinkenntnisse haben, werden vielleicht schon bemerkt haben, dass im Namen Variablen ein bisher nicht diskutierter Aspekt versteckt ist: dass sich der Wert einer Variable ändern kann.
      </p>

      <p>In unseren Beispielen wird dies nur auf eine Art passieren, nämlich der Zuweisung eines neuen Werts an eine bereits bestehende Variable. Dabei bleiben Name und Typ der Variable erhalten, aber der Wert der Variablen kann sich ändern.</p>

      <p>Wenn einer Variable A ein neuer Wert zugewiesen wird, so wird der alte Wert gelöscht und durch den neuen ersetzt. Besonders interessant ist dabei der Fall, wenn der Wert einer Variable einer anderen Variable zugewiesen wird: Sollte der Wert von Variable A einer Variable B zugewiesen werden, haben nun Variable A und B den gleichen Wert - nämlich den Wert von A.
      </p>
    </div>

    <div class="row animate glow delay-2">
      <h2>Beispiel: Gewürzgläser</h2>
      <p>
        Diese etwas abstrakte Erklärung wollen wir nun anhand eines Beispiels 
        konkreter machen. Stellen Sie sich dazu drei Gewürzgläser vor, die wir als 
        Variablen des Typs 'Gewürz' modelliert haben. Diese können z. B. “P” wie 
        Pfeffer, “S” wie Salz oder “O” wie Oregano sein. 
      </p>

      <p>Sie können durch Drag&Drop die Variablen einander zuweisen. Probieren Sie es am Besten einfach selbst mal aus!</p>
    </div>

    <div class="row animate glow delay-3">
      <div class="shelf">
        <div v-for="jar in jars" 
         :key="jar.id" 
         class="drag-el" 
         draggable="true"
         @dragstart="startDrag($event, jar)"
         @drop="onDrop($event, jar.id)"
         @dragenter.prevent
         @dragover.prevent>
          <VariableBox
                    :name = 'jar.name'
                    type="Gewürz"
                    :val = 'jar.val' 
                />
        </div>
        <div @click="reset()" class="reset">
          <img :src= 'image' id="reset-button">
        </div>
      </div>
      <p></p>
    </div>

    <div class="row animate glow delay-4">
      <p>
        Sie werden schnell bemerken, dass die Funktionsweise deutlich anders ist, als 
        man es von normalen Gewürzgläsern gewöhnt ist. Einerseits kann man nur ein 
        Gewürz in einem Glas behalten (also z. B. keine Oregano-Salz-Mischung 
        generieren), andererseits verlieren wir auch kein Oregano, wenn wir es einem 
        oder mehreren neuen Gläsern zuweisen.</p>
      <p>
        Wie Sie bemerkt haben, ist, dass jede Zuweisung zu einem Verlust eines Gewürzes führt und es entsprechend auch nicht möglich ist, alle Gewürze in das Glas mit dem richtigen Anfangsbuchstaben zu bringen.</p>
      <p>
        In Aufgabe 2 sollen Sie zeigen, wie es mit Hilfe einer sogenannten Hilfsvariable möglich ist, die Werte von zwei oder mehreren Variablen zu tauschen.</p>
    </div>
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}
  .shelf {
    text-align: center;
    display: flex;
    border: solid 1px black;
    border-radius: 30px;
  }
  .reset{
    width: 7%;
    margin: auto auto;
    min-height: 10px;
    padding: 10px;
    cursor: pointer;
  }
  #reset-button{
    max-width: 100%;
  }
  .drag-el {
    width: 20%;
    margin: 5px auto;
    padding: 10px;
    min-height: 10px;
  }
  .feedback {
    text-align: center;
    width: 50%;
    margin: 5px auto;
    padding: 10px;
    min-height: 10px;
    background-color: lightcoral;
  }

  .animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.4s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 0.8s;
}
</style>
