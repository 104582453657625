export { createMyInformatikOSA, finishModule, getMyInformatikOSA }

async function finishModule(idMyOSA, params){
    let res = null;
    try {
      const response = await fetch(
        process.env.VUE_APP_STRAPI_API_URL + "/api/inf-status-osas/"+idMyOSA,
        {
          method: "PUT",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          },
          body: JSON.stringify({ data: params})
          }
      );
      res = await response.json();  
      } catch (error) {
        console.error(error)
      }
    return res;
}

async function createMyInformatikOSA(user){
    if(user){
        let objJSON = JSON.stringify({ data: {introFinished: false, sortingFinished: false, securityFinished: false, fitIFinished: false} });
          try {
          const response = await fetch(
            process.env.VUE_APP_STRAPI_API_URL + "/api/inf-status-osas",
            {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
              },
              body: objJSON
            }
          );
          const res = await response.json();
          if (res.length > 1) {
            console.error("INFORMATIK OSA: Too many entries received");
          } else if (!res || res.length == 0) {
            //no entry for this user --> not started with informatik osa
            return {}
          } else {
            return res[0];
          }
        } catch (error) {
          console.error(error);
        }
      }
}


async function getMyInformatikOSA(user) {
    if(user){
      try {
      const response = await fetch(
        process.env.VUE_APP_STRAPI_API_URL + "/api/inf-status-osa/myStatus",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          },
        }
      );
      const res = await response.json();
      if (res.length > 1) {
        console.error("INFORMATIK OSA: Too many entries received");
      } else if (!res || res.length == 0) {
        //no entry for this user --> not started with informatik osa
        return {}
      } else {
        return res[0];
      }
    } catch (error) {
      console.error(error);
    }
  }
}