/**
 * Scrolls to first element in DOM with a given class name
 * @param {String} className class name of object to scroll to
 * @param {Object} options options to use when scrolling (behaviour, block, ...), e.g. {behavior: 'smooth'}
 */
export function scrollToElementByClassName(className, options) {// eslint-disable-line no-unused-vars
    const el = document.getElementsByClassName(className)[0];
    
    if (el) {
      el.scrollIntoView(options);
    }
}

/**
 * Scrolls to element by id
 * @param {String} objectId id of object to scroll to
 * @param {Object} options options to use when scrolling (behaviour, block, ...), e.g. {behavior: 'smooth'}
 */
export function scrollToElementById(objectId, options) {// eslint-disable-line no-unused-vars
    const el = document.getElementById(objectId);
    
    if (el) {
      el.scrollIntoView(options);
    }
}

/**
 * Scrolls to the top of the page
 */
export function scrollToTop(){
  window.scrollTo(-1, 0);
}