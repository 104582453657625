<script setup>
import { ref, defineEmits, onMounted } from 'vue'
import store from "@/store"
import VariableBox from '@/components/inf/variables/helpers/VariableBox.vue'

const feedback = ref("")
const errors = ref(0)
const swaps = ref(0)

const jars = ref([
    {id: 0, name: "P", val: "Salz"},
    {id: 1, name: "S", val: "Oregano"},
    {id: 2, name: "O", val: "Pfeffer"},
    {id: 3, name: "Leer", val: ""}
  ])
const counter = ref({
    Salz: 1,
    Oregano: 1,
    Pfeffer: 1
  })

const emit = defineEmits(['disableWeiter', 'enableWeiter'])

const startDrag = (event, jar) => {
  feedback.value = ""
  event.dataTransfer.dropEffect = "move"
  event.dataTransfer.effectAllowed = "move"
  event.dataTransfer.setData("val", jar.val)
}

const onDrop = (event, id) => {
  const val = event.dataTransfer.getData("val")
  const dropVal = jars.value[id].val
  let validMove = true
  if(dropVal != "Leer" && dropVal != val) {
    if(counter.value[dropVal] - 1 < 1){
      getFeedback(dropVal)
      errors.value += 1
      console.log('errors: ' + errors.value)
      validMove = false
    } 
  }
  if(validMove){
    counter.value[dropVal] -= 1
    counter.value[val] += 1
    jars.value[id].val = val
    swaps.value += 1
    //console.log('swaps: ' + swaps.value)
    checkFinished()
    }
}

onMounted(() => {
    console.log(`the component is now mounted.`)
    emit('disableWeiter')
})

function checkFinished(){
  if(jars.value[0].val == "Pfeffer" && jars.value[1].val == "Salz" && jars.value[2].val == "Oregano"){
    console.log('finished')
    emit('enableWeiter')
    logAction(swaps.value, errors.value)
  }
}

const logAction = (sw, err) => {
  const feedbackLog = {'swaps': sw, 'errors': err}
  //console.log(feedbackLog)
  store.commit('variablesA2Log', JSON.parse(JSON.stringify(feedbackLog)))
  store.dispatch('variablesResultsPost')
}

const getFeedback = (val) => {
  feedback.value = val
}
</script>
<template>
  <div class = 'container'>
    <div class="row animate glow delay-1">
     
      <h1>Aufgabe 2: Das vierte Glas</h1>
      <p>
        Nachdem Sie schon stundenlang probieren, Ihre Gewürzgläser richtig zu ordnen, bemerkt Sie Ihre Mitbewohnerin und erklärt Ihnen, dass es ja noch ein leeres Gewürzglas im Schrank gibt. Ist das bereits alles, was Sie zur Lösung Ihres Problems benötigen?
      </p>

      <p>Probieren Sie, alle Gewürze den Gläsern mit dem richtigen Anfangsbuchstaben zuzuweisen. Passen Sie dabei auf, dass Sie kein Gewürz durch Zuweisung verlieren. Welches Gewürz sich am Ende im anfangs leeren Glas befindet, ist dabei egal.</p>

      <p>Schaffen Sie es auch mit nur vier Zuweisungen?</p>
    </div>

    <div class="row animate glow delay-2">
      <p></p>
      <p v-if="feedback" class="error-message">Sie haben den Wert "{{feedback}}" verloren.</p>
      <p v-if="(jars[0].val == 'Pfeffer' && jars[1].val == 'Salz' && jars[2].val == 'Oregano')" class="finish">Gratulation! Alle Gewürze sind im richtigen Glas - und Sie haben sogar noch ein Gewürz mehr als ursprünglich.</p>
      <div class="shelf" v-if = "!(jars[0].val == 'Pfeffer' && jars[1].val == 'Salz' && jars[2].val == 'Oregano')">
        <div v-for="jar in jars" 
         :key="jar.id" 
         class="drag-el" 
         draggable="true"
         @dragstart="startDrag($event, jar)"
         @drop="onDrop($event, jar.id)"
         @dragenter.prevent
         @dragover.prevent>
          <VariableBox
                    :name = 'jar.name'
                    type="Gewürz"
                    :val = 'jar.val' 
                />
        </div>
      </div>
      <div class="shelf" v-if = "(jars[0].val == 'Pfeffer' && jars[1].val == 'Salz' && jars[2].val == 'Oregano')">
        <div v-for="jar in jars" 
         :key="jar.id" 
         class="drag-el">
          <VariableBox
                    :name = 'jar.name'
                    type="Gewürz"
                    :val = 'jar.val' 
                />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}
  .error-message {	
      width: auto;
      text-align: center;		
      border: 1px solid;
			margin: 0 auto;
			padding: 15px 50px 15px 50px;
			background-repeat: no-repeat;
			background-position: 10px center;
      color: #D8000C;
			background-color: #FFBABA;}
  .finish {
      width: auto;
      text-align: center;	
      border: 1px solid;
			margin: 0 auto;
			padding: 15px 50px 15px 50px;
			background-repeat: no-repeat;
			background-position: 10px center;
      color: #4F8A10;
			background-color: #DFF2BF;}
  .shelf {
    text-align: center;
    display: flex;
  }
  .drag-el {
    width: 25%;
    margin: 5px auto;
    padding: 10px;
    min-height: 10px;
  }
  .feedback {
    text-align: center;
    width: 50%;
    margin: 5px auto;
    padding: 10px;
    min-height: 10px;
    background-color: lightcoral;
  }

  .animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.4s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 0.8s;
}
</style>
