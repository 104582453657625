<script setup>
  import store from "@/store"
  import { onMounted } from 'vue'

  onMounted(() => {
    window.scrollTo(0, 0);
})

</script>

<template>
  <div class = 'container'>
    <div class = 'row animate glow delay-1'>
      <h1>Appendix 1: Datenstrukturen und Datenbanken</h1>
      <p>
        Das vorangehende Beispiel ist nur als Illustration der grundlegenden 
        Funktionsweise von Variablen zu verstehen. In der Praxis ist es sehr unüblich, 
        einzelne Daten zur selben Person in unabhängigen Variablen zu speichern. 
        Stattdessen werden Sie in komplexeren Datenstrukturen zusammengefasst. 
      </p>
      <p>Außerdem werden wir in den meisten Fällen Personen nicht einzeln 
         behandeln, sondern dieselben Daten zu verschiedenen Personen speichern 
         wollen. Dies kann z. B. mit einer Tabelle wie weiter unten bewerkstelligt 
         werden.</p>
    </div>
    <div class="row animate glow delay-2 table">
        <table>
          <tr><th colspan="5">Person</th></tr>
          <tr>
            <th class="attribute">{{store.state.variablesModule.assignment1.final[0].name}}</th>
            <th class="attribute">{{store.state.variablesModule.assignment1.final[1].name}}</th>
            <th class="attribute">{{store.state.variablesModule.assignment1.final[2].name}}</th>
            <th class="attribute">{{store.state.variablesModule.assignment1.final[3].name}}</th>
            <th class="attribute">{{store.state.variablesModule.assignment1.final[4].name}}</th>
          </tr>
          <tr><td colspan="5" class = "dots">...</td></tr>
          <tr>
            <td>Stefan</td>
            <td>Watzlawick</td>
            <td>1999-11-30</td>
            <td>1070</td>
            <td>Wien</td>
          </tr>
          <tr>
            <td>Beri</td>
            <td>Taletovic</td>
            <td>1997-10-11</td>
            <td>1040</td>
            <td>Wien</td>
          </tr>
          <tr>
            <td>Hannah</td>
            <td>Pfanner</td>
            <td>1990-07-12</td>
            <td>5020</td>
            <td>Salzburg</td>
          </tr>
          <tr><td colspan="5" class = "dots">...</td></tr>
        </table>
    </div>
    <div class="row animate glow delay-3">
        <p>Solche Tabellen sind ein zentrales Konzept in sogenannten relationalen Datenbanken, mit denen wir uns im Modul "Datenbanken" etwas genauer befassen werden.</p>
        <p>Wir widmen uns in der Zwischenzeit dem zweiten großen Aspekt von Variablen: der Zuweisung von neuen Werten!</p>
    </div>
  </div>
</template>

<style scoped>
p{
  line-height: 1.3;
}
th, td {
  border: 1px solid;
  text-align: center;
}

table {
  margin: 0 auto;
}

.table {
  width: 100%;
}

.attribute {
  border-bottom: 1px solid;
  border-bottom-width: 5px;
  border-bottom-style: double;
}

table {
  min-width: 500px;
  width: 70%;
}

.dots {
  font-weight: bolder;
  font-size: x-large;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.4s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 0.8s;
}
</style>
