<script setup>
import { onMounted } from "vue";
// import * as scrolling from "@/helpers/scroll-helper.js";
import VariableBox from "@/components/inf/variables/helpers/VariableBox.vue";
onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Namen, Werte und Typen</h1>

      <p>
        In der Informatik wird oft angenommen, dass eine Variable aus drei
        Teilen besteht: dem Namen der Variable, dem Typ der Variable als auch
        ihr Wert. In diesem Modul werden wir die folgende Darstellung von
        Variablen in Form von dreigeteilten Boxen wie der Folgenden verwenden:
      </p>
    </div>

    <div class="row">
      <div id="examples" class="animate glow delay-3">
        <div><VariableBox name="NAME" type="TYP" val="WERT" /></div>
      </div>
    </div>

    <div class="row animate glow delay-2">
      <p>
        Der <strong>Name</strong> einer Variable ist eine Bezeichnung, die dazu
        dient, die Variable eindeutig zu identifizieren. Das bedeutet aber auch,
        dass es in einem Computerprogramm für einen Namen nur eine Variable mit
        diesem Namen geben darf, selbst wenn sich der Typ oder Wert
        unterscheiden sollte. Man kann sich die Beziehung zwischen dem Namen und
        dem Wert einer Variable als Pfeildiagramm darstellen, in dem von jedem
        Namen genau ein Pfeil weggeht und auf einen Wert zeigt.
      </p>

      <p>
        Der <strong>Wert</strong> einer Variable ist (etwas selbsterklärend) der
        Wert, den die Variable beinhaltet. In der Informatik können das eine
        große Menge an verschiedenen Dingen sein, von Zeichen, Wörtern oder
        Zahlen als auch abstraktere Entitäten, wie Referenzen auf Objekte (dazu
        mehr im Studium).
      </p>

      <p>
        Während eine Variable grundsätzlich viele Werte annehmen kann, ist es in
        vielen Programmiersprachen üblich, dass die möglichen Werte einer
        konkreten Variable von vornherein eingeschränkt wird. Der
        <strong>Typ</strong> einer Variable beschreibt, welche Art von Werten
        eine Variable annehmen darf. Im Folgenden werden wir nur die Typen
        <em>Text</em> und <em>Zahl</em> verwenden.
      </p>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}
#examples {
  display: flex;
  justify-content: center;
  gap: 10%;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.4s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 0.8s;
}
</style>
