<script setup>
import { ref, onMounted } from "vue";
//import * as scrolling from "@/helpers/scroll-helper.js";
import VariableBox from "@/components/inf/variables/helpers/VariableBox.vue";

const image = ref(require("@/assets/inf/variable/alex-image.png"));

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Was sind Variablen?</h1>

      <p>
        Daten und der Umgang damit sind ein fundamentaler Bereich der Informatik
        und in unserer digitalisierten Welt allgegenwärtig. Während viele wohl zuerst einmal an
        Datenspeichern oder Big Data denken würden, ist das dynamische Arbeiten
        mit und Weiterverarbeiten von Daten auch eine grundsätzliche
        Herangehensweise in der Programmierung.
      </p>

      <p>
        Variablen sind eine der einfachsten Arten, mit Daten zu arbeiten. Die
        Idee ist, dass man statt mit konkreten Werten nur mit Behältern
        arbeitet. Wir können diese Behälter (ähnlich zu Variablen in der
        Mathematik) über ihre Namen unterscheiden und wissen manchmal auch noch,
        welche Art von Daten drinnen ist (z. B. ob es ein Behälter für Zahlen
        oder Text ist). Außerdem können diese Behälter auch neu befüllt werden, was
        aber unter anderem zum Verlust des alten Inhalts führen kann. In Aufgabe
        2 dieses Moduls sehen wir, dass das ein Austauschen von zwei oder
        mehreren Werten oft unerwartet umständlich ist.
      </p>

      <p>
        Um Datenerfassung und Variablen als technisches Werkzeug
        besser kennenzulernen, wollen wir uns auf das Speichern von persönlichen
        Daten einer Person in Variablen befassen. Unten sehen Sie eine kurze
        Vorstellung von Alex und wie persönliche Informationen in Variablen
        gespeichert werden können.
      </p>
    </div>

    <div class="row">
      <div id="example">
        <div id="image-box" class="animate glow delay-4">
          <img :src="image" id="alex-image" />
          <div class="bubble animate glow delay-4">
            <p id="speech-text">
              Hallo! <br />
              Mein Name ist Alex, <br />
              ich bin 26 Jahre alt <br />
              und wohne in Wien!
            </p>
          </div>
        </div>
        <div id="variables-box" class="animate glow delay-5">
          <div class="animate glow delay-3">
            <VariableBox name="Vorname" type="Text" val='"Alex"' />
          </div>
          <div class="animate glow delay-4">
            <VariableBox name="Alter" type="Zahl" val="26" />
          </div>
          <div class="animate glow delay-5">
            <VariableBox name="Wohnort" type="Text" val='"Wien"' />
          </div>
        </div>
      </div>
    </div>
    <a
      href="https://www.flaticon.com/free-icons/person"
      title="person icons"
      class="credit-link"
      target="_blank"
      >Person icons created by Freepik - Flaticon</a
    >
  </div>
</template>

<style scoped>
.credit-link {
  font-size: 10px; /* or even smaller if you want */
  color: #555; /* Optional: change the color if you like */
  text-align: center; /* Optional: centers the link */
  display: block; /* Optional: places the link on its own line */
  margin-top: 10px; /* Optional: adds space above the link */
}
p {
  line-height: 1.3;
}
#example {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
#image-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
#alex {
  text-align: right;
}
#alex-image {
  min-width: 100px;
  width: 30%;
  height: auto;
  filter: drop-shadow(5px 5px 2px #a3a3a3);
}
#bubble-box {
  text-align: left;
  padding: 20px;
}
#example-text {
  font-weight: bold;
  text-align: center;
}
#text-box {
  width: 900px;
}
#variables-box {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
#speech-text {
  font-size: 16pt;
}

.bubble {
  position: relative;
  background: #ffffff;
  color: #000000;
  font-family: Arial;
  font-size: 18px;
  filter: drop-shadow(5px 5px 2px #a3a3a3);
  text-align: center;
  width: 250px;
  height: 150px;
  border-radius: 10px;
  padding: 0px;
  border: #000000 solid 3px;
}
.bubble:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: transparent #ffffff;
  border-width: 20px 20px 20px 0;
  top: 50%;
  left: -20px;
  margin-top: -20px;
}
.bubble:before {
  content: "";
  position: absolute;
  width: 0;
  z-index: 0;
  border-style: solid;
  border-color: transparent #000000;
  border-width: 23px 23px 23px 0;
  top: 50%;
  left: -24.5px;
  margin-top: -23px;
  display: block;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arrow-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.the-arrow {
  width: 1px;
  transition: all 0.2s;
}
.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}
.the-arrow.-left > .shaft {
  width: 0;
  background-color: #4c4c4c;
}
.the-arrow.-left > .shaft:before,
.the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #4c4c4c;
}
.the-arrow.-left > .shaft:before {
  transform: rotate(0);
}
.the-arrow.-left > .shaft:after {
  transform: rotate(0);
}
.the-arrow.-right {
  top: 3px;
}
.the-arrow.-right > .shaft {
  width: 1px;
  transition-delay: 0.2s;
}
.the-arrow.-right > .shaft:before,
.the-arrow.-right > .shaft:after {
  width: 20px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}
.the-arrow.-right > .shaft:before {
  transform: rotate(70deg);
}
.the-arrow.-right > .shaft:after {
  transform: rotate(-70deg);
}
.the-arrow > .shaft {
  background-color: #4c4c4c;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}
.the-arrow > .shaft:before,
.the-arrow > .shaft:after {
  background-color: #4c4c4c;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}
.the-arrow > .shaft:before {
  transform-origin: top right;
}
.the-arrow > .shaft:after {
  transform-origin: bottom right;
}

.animated-arrow {
  display: inline-block;
  color: #4c4c4c;
  font-size: 1.9em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
}
.animated-arrow:hover {
  color: #808080;
}
.animated-arrow:hover > .the-arrow.-left > .shaft {
  width: 64px;
  transition-delay: 0.1s;
  background-color: #808080;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before,
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  width: 20px;
  transition-delay: 0.1s;
  background-color: #808080;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before {
  transform: rotate(40deg);
}
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  transform: rotate(-40deg);
}
.animated-arrow:hover > .main {
  transform: translateX(17px);
  transform: translateX(80px);
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft {
  width: 0;
  transform: translateX(200%);
  transition-delay: 0;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before,
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  width: 0;
  transition-delay: 0;
  transition: all 0.1s;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
  transform: rotate(0);
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  transform: rotate(0);
}
.animated-arrow > .main {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}
.animated-arrow > .main > .text {
  margin: 0 16px 0 0;
  line-height: 1;
}
.animated-arrow > .main > .the-arrow {
  position: relative;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.4s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 0.8s;
}
</style>
