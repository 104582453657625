<script setup>
import { ref, onMounted } from "vue";
// import * as scrolling from "@/helpers/scroll-helper.js";
import VariableBox from "@/components/inf/variables/helpers/VariableBox.vue";

const image = ref(require("@/assets/inf/variable/alex-image.png"));

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-2">
      <h2>Warum sind Variablen wichtig?</h2>

      <p>
        Programme werden nur selten für eine konkrete Einzelsituation
        geschrieben, sondern es soll stattdessen eine größere Menge von
        ähnlichen Problemen auf automatisierter Weise gelöst werden. Analog dazu
        sollten auch gute Rezepte in Kochbüchern nicht auf eine spezifische
        Küche zugeschnitten sein, sondern sollten von vielen Menschen in
        unterschiedlichsten Küchen umgesetzt werden können - solange die im
        Rezept spezifizierten Werkzeuge und Zutaten zur Verfügung stehen.
      </p>

      <p>
        Da ein Programm nicht alle Einzelprobleme auf die gleiche Art lösen
        kann, legt man in der Programmierung einen großen Fokus darauf,
        herauszufinden, welche Daten eines Einzelproblems für eine
        generalisierte Lösungsstrategie notwendig sind. Beispielsweise reicht
        es, einen Geburtstag und das heutige Datum zu wissen, um das Alter einer
        Person berechnen zu können. Die Anzahl der Zahnarztbesuche hingegen kann
        uns zwar als Indiz dienen, aber nicht als verlässliche Methode zur
        genauen Berechnung des Alters verwendet werden.
      </p>
    </div>
    <div class="row">
      <div id="example">
        <div id="image-box" class="animate glow delay-4">
          <img :src="image" id="alex-image" />
          <div class="bubble animate glow delay-4">
            <p id="speech-text">
              Hallo! <br />
              Mein Name ist Alex, <br />
              ich bin 26 Jahre alt <br />
              und wohne in Wien!
            </p>
          </div>
        </div>
        <!--<div class='arrow-box  animate glow delay-3'>
          <ul>
            <li>
              <a class='animated-arrow' href='https://google.com'>
                <span class='the-arrow -left'>
                  <span class='shaft'></span>
                </span>
                <span class='main'>
                  <span class='text'>
                    In Variable
        n
                    <br>
                    umwandeln!
                  </span>
                  <span class='the-arrow -right'>
                    <span class='shaft'></span>
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>-->
        <div id="variables-box" class="animate glow delay-5">
          <div class="animate glow delay-3">
            <VariableBox name="Vorname" type="Text" val='"Alex"' />
          </div>
          <div class="animate glow delay-4">
            <VariableBox name="Alter" type="Zahl" val="26" />
          </div>
          <div class="animate glow delay-5">
            <VariableBox name="Wohnort" type="Text" val='"Wien"' />
          </div>
        </div>
      </div>
      <a
        href="https://www.flaticon.com/free-icons/person"
        title="person icons"
        class="credit-link"
        target="_blank"
        >Person icons created by Freepik - Flaticon</a
      >
    </div>
    <p></p>
    <div class="row animate glow delay-3">
      <p>
        Die Variablen werden hier (als auch im Verlauf des weiteren Moduls) in
        drei Teile (Name, Werte und Typen) geteilt, die wir im nächsten Kapitel
        besprechen wollen.
      </p>
    </div>
  </div>
</template>

<style scoped>
.credit-link {
  font-size: 10px; /* or even smaller if you want */
  color: #555; /* Optional: change the color if you like */
  text-align: center; /* Optional: centers the link */
  display: block; /* Optional: places the link on its own line */
  margin-top: 10px; /* Optional: adds space above the link */
}
#example {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
#image-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
#alex {
  text-align: right;
}
#alex-image {
  min-width: 100px;
  width: 30%;
  height: auto;
  filter: drop-shadow(5px 5px 2px #a3a3a3);
}
#bubble-box {
  text-align: left;
  padding: 20px;
}
#example-text {
  font-weight: bold;
  text-align: center;
}
#text-box {
  width: 900px;
}
#variables-box {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
#speech-text {
  font-size: 16pt;
}

.bubble {
  position: relative;
  background: #ffffff;
  color: #000000;
  font-family: Arial;
  font-size: 18px;
  filter: drop-shadow(5px 5px 2px #a3a3a3);
  text-align: center;
  width: 250px;
  height: 150px;
  border-radius: 10px;
  padding: 0px;
  border: #000000 solid 3px;
}
.bubble:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: transparent #ffffff;
  border-width: 20px 20px 20px 0;
  top: 50%;
  left: -20px;
  margin-top: -20px;
}
.bubble:before {
  content: "";
  position: absolute;
  width: 0;
  z-index: 0;
  border-style: solid;
  border-color: transparent #000000;
  border-width: 23px 23px 23px 0;
  top: 50%;
  left: -24.5px;
  margin-top: -23px;
  display: block;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arrow-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.the-arrow {
  width: 1px;
  transition: all 0.2s;
}
.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}
.the-arrow.-left > .shaft {
  width: 0;
  background-color: #4c4c4c;
}
.the-arrow.-left > .shaft:before,
.the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #4c4c4c;
}
.the-arrow.-left > .shaft:before {
  transform: rotate(0);
}
.the-arrow.-left > .shaft:after {
  transform: rotate(0);
}
.the-arrow.-right {
  top: 3px;
}
.the-arrow.-right > .shaft {
  width: 1px;
  transition-delay: 0.2s;
}
.the-arrow.-right > .shaft:before,
.the-arrow.-right > .shaft:after {
  width: 20px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}
.the-arrow.-right > .shaft:before {
  transform: rotate(70deg);
}
.the-arrow.-right > .shaft:after {
  transform: rotate(-70deg);
}
.the-arrow > .shaft {
  background-color: #4c4c4c;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}
.the-arrow > .shaft:before,
.the-arrow > .shaft:after {
  background-color: #4c4c4c;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}
.the-arrow > .shaft:before {
  transform-origin: top right;
}
.the-arrow > .shaft:after {
  transform-origin: bottom right;
}

.animated-arrow {
  display: inline-block;
  color: #4c4c4c;
  font-size: 1.9em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
}
.animated-arrow:hover {
  color: #808080;
}
.animated-arrow:hover > .the-arrow.-left > .shaft {
  width: 64px;
  transition-delay: 0.1s;
  background-color: #808080;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before,
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  width: 20px;
  transition-delay: 0.1s;
  background-color: #808080;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before {
  transform: rotate(40deg);
}
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  transform: rotate(-40deg);
}
.animated-arrow:hover > .main {
  transform: translateX(17px);
  transform: translateX(80px);
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft {
  width: 0;
  transform: translateX(200%);
  transition-delay: 0;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before,
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  width: 0;
  transition-delay: 0;
  transition: all 0.1s;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
  transform: rotate(0);
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  transform: rotate(0);
}
.animated-arrow > .main {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}
.animated-arrow > .main > .text {
  margin: 0 16px 0 0;
  line-height: 1;
}
.animated-arrow > .main > .the-arrow {
  position: relative;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.4s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 0.8s;
}
p {
  line-height: 1.3;
}
</style>
