<script setup>
import { defineProps} from 'vue'

const props = defineProps({
                  name: String,
                  type: String,
                  val: String
                 })
</script>

<template>
  <div id = 'variable-box'>
    <div id = 'name'>{{ props.name }}</div>
    <div id = 'wert'>{{ props.val==""?"NULL":props.val }}</div>
    <div id = 'type'>{{ props.type }}</div>
  </div>
</template>

<style scoped>
  #variable-box { display: flex; flex-direction: column; align-items: center; min-width: 150px; max-width: 250px;
                  border: solid black 3px; border-radius: 20% / 30%; overflow: hidden;
                  font-size: 16pt;}
  #name { color: #fbfeff;
          font-weight: bold;
          width: 100%;
          background-color: #006bac; 
          text-align: center;
          padding: 10px;
          }
  #wert { font-weight: bold;
          width: 100%;
          text-align: center;
          padding: 10px;
          }
  #type { color: #fbfeff;
          font-weight: bold;
          width: 100%;
          background-color: #00446e; 
          text-align: center;
          padding: 10px;
          }
</style>