<script setup>

import { ref, onMounted } from 'vue'
import VariableBox from '@/components/inf/variables/helpers/VariableBox.vue'
//import * as scrolling from "@/helpers/scroll-helper.js";

onMounted(() => {
  window.scrollTo(0, 0);
})

const form = ref({ name: '', type: '', val: '' })
const var_list = ref([])
const err_msg = ref('')

function addVariable() {
  if (getFeedback()) {
    var_list.value.push(form.value)
    clearInput()
  }
}
function getFeedback() {
  if (form.value.name == "") {
    err_msg.value = "Geben Sie der Variable einen Namen."
    return false
  }
  else if (isDuplicate()) {
    err_msg.value = "Dieser Name ist bereits belegt."
    return false
  }
  else if (isInvalidName()) {
    err_msg.value = "Kein gültiger Name."
    return false
  }
  else if (isInvalidValue()) {
    err_msg.value = "Kein gültiger Wert."
    return false
  }
  else {
    err_msg.value = ''
    return true
  }
}
function isDuplicate() {
  let isDuplicate = false
  var_list.value.forEach(
    function (vari) {
      if (vari.name === form.value.name) { isDuplicate = true }
    })
  return isDuplicate
}
function isInvalidName() {
  const name_regex = new RegExp("[a-zA-Z]{1,20}")
  return !name_regex.test(form.value.name)
}
function isInvalidValue() {
  let isInvalidValue = false
  if (form.value.type == "Zahl" && (form.value.val < 1 || form.value.val > 100)) {
    isInvalidValue = true
  }
  else if (form.value.type == "Datum") {
    const date = new Date(form.value.val).getDate()
    const start = new Date("1990-01-01").getDate()
    const end = new Date("2024-12-31").getDate()
    if (date < start || date > end) {
      isInvalidValue = true
    }
  }
  return isInvalidValue
}
function clearInput() {
  form.value = { name: '', type: '', val: '' }
}
</script>

<template>
  <div class='container'>
    <div class="row animate glow delay-1">
      <h1>Tutorial: Variablen deklarieren</h1>
      <p>
        Bisher haben Sie das Konzept <strong>Variable</strong> und deren drei Bestandteile (Namen, Typ und Wert) nur in
        abstrakter Weise kennengelernt. Aber wie verwendet man nun Variablen? Da in verschiedenen Programmiersprachen
        Variablen unterschiedlich verwendet werden, ist diese Frage schwer allgemein zu beantworten.
      </p>
      <p>
        Jedoch ist es in vielen Programmiersprachen - wie auch der im ersten Semester des Informatikstudiums an der TU
        Wien unterrichteten Sprache <strong>Java</strong> - notwendig, vor der ersten Verwendung einer Variable einen Namen und
        einen Typ zuzuweisen. Dies wird als <strong>Variablendeklaration</strong> bezeichnet. Oft wird dabei der
        Variable auch ein initialer Wert zugewiesen.
      </p>
    </div>

    <div class="row animate glow delay-2">
      <h2>Namen</h2>
      <p>
        Oft ist es sinnvoll, die möglichen Variablennamen bereits auf eine bestimmte Menge von Zeichenketten
        einzuschränken. Einige dieser Einschränkungen finden Sie nahezu universell in allen Programmiersprachen (z. B.
        Eindeutigkeit des Namens), andere gelten nur konkret für unsere Beispiele.
      </p>
      <p>In unserem Beispiel arbeiten wir mit folgenden Konventionen:
      <ul>
        <li>Jeder Name darf an maximal eine Variable vergeben werden.</li>
        <li>Ein Name darf nur aus Klein- und Großbuchstaben des lateinischen Alphabets bestehen (also weder Zahlen,
          Leer- oder Sonderzeichen beinhalten).</li>
        <li>Ein Name muss zwischen 1 und 20 Zeichen lang sein (im Speziellen darf der Name nicht leer sein).</li>
      </ul>
      </p>

      <h2>Typen und Werte</h2>
      <p>
        Die Anzahl der verschiedenen Typen und entsprechend auch der Werte, die eine Variable annehmen kann, ist in den
        meisten Programmiersprachen ebenso limitiert. Im folgenden Beispiel als auch der ersten Aufgabe nehmen wir
        folgende drei Typen und Wertebereiche an:
      <ul>
        <li><strong>Text</strong>: eine beliebige Zeichenkette zwischen 1 und 20 Zeichen</li>
        <li><strong>Zahl</strong>: eine ganzzahlige Zahl zwischen 1 und 9999</li>
        <li><strong>Datum</strong>: ein Datum zwischen dem 1.1.1990 und dem 31.12.2024</li>
      </ul>
      Wird bei der Deklaration der Variable kein oder ein ungültiger Wert zugewiesen, wird ihr der leere Wert
      zugewiesen.
      </p>
    </div>
    <div class="row animate glow delay-3">
      <p>Probieren Sie selbst aus Variablen von verschiedenen Typen zu deklarieren! Versuchen Sie auch einige der obigen
        Konventionen zu verletzen. </p>
      <p v-if="err_msg" class="error-message">{{ err_msg }}</p>
      <div id='assignment-box'>
        <div id='form-box'>
          <div class='input-row'>
            <input id='name-input' type='text' v-model='form.name' pattern="[a-zA-Z]{1,20}" placeholder="Name">
          </div>

          <div class='input-row'>
            <select id='type-input' v-model='form.type'>
              <option value="" disabled selected hidden>Typ</option>
              <option value='Text'>Text</option>
              <option value='Zahl'>Zahl</option>
              <option value='Datum'>Datum</option>
            </select>
          </div>

          <div class='input-row'>
            <div v-if='form.type == "Zahl"'>
              <input id='value-input' type='number' v-model='form.val' min="1" max="100" placeholder="Wert">
            </div>
            <div v-if="form.type == 'Text'">
              <input id='value-input' type='text' v-model='form.val' minlength="1" maxlength="10" placeholder="Wert">
            </div>
            <div v-if="form.type == 'Datum'">
              <input id='value-input' type='date' v-model='form.val' min="1990-01-01" max="2024-12-31"
                placeholder="Wert">
            </div>
          </div>

          <button class='custom-btn' @click='addVariable()' :disabled="form.name == '' || form.type == ''">Variable
            hinzufügen</button>
        </div>
        <div id='variables'>
          <div v-for='item in var_list' :key='item.id'>
            <VariableBox :name='item.name' :type='item.type' :val='item.val' />
          </div>
        </div>
      </div>
      <p></p>
      <p>Wenn Sie denken, dass Sie die Deklaration von Variablen ausreichend verstanden haben,
        können Sie mit der Aufgabe 1 fortfahren!</p>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

#assignment-box {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid black;
  border-radius: 30px;
  overflow: hidden;
}

.input-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}

label {
  font-size: 12pt;
  font-weight: bold;
}

#form-box {
  border-right: 1px black solid;
  width: 200px;
  padding: 20px 10px;
}

#name-input,
#type-input,
#value-input {
  width: 160px;
  height: 30px;
}

:invalid {
  background-color: pink
}

#variables {
  width: auto;
  padding: 10px 30px;
  display: flex;
}

.input-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

:invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.6rem #ff0000;
}

.custom-btn:disabled {
  background-color: grey;
}

.error-message {
  width: auto;
  text-align: center;
  border: 1px solid;
  margin: 0 auto;
  padding: 15px 50px 15px 50px;
  background-repeat: no-repeat;
  background-position: 10px center;
  color: #D8000C;
  background-color: #FFBABA;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }

  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.4s;
}

.delay-2 {
  animation-delay: 0.5s;
}

.delay-3 {
  animation-delay: 0.6s;
}

.delay-4 {
  animation-delay: 0.7s;
}

.delay-5 {
  animation-delay: 0.8s;
}
</style>
