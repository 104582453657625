<script setup>
//import store from "@/store"
import { onMounted } from "vue";

onMounted(() => {
  //console.log(store.state.variablesModule)
});
</script>

<template>
  <div class="container">
    <div class="row"></div>
    <div class="row animate glow delay-1">
      <h1>Appendix 2: Sortieren und Tauschen</h1>
      <p>
        Manche von Ihnen haben sich bei der Aufgabe vielleicht gefragt, ob das
        Austauschen von Variablen im Programmieralltag überhaupt notwendig ist?
        Wir hätten ja schließlich den einzelnen Variablen auch manuell die
        richtigen Werte zuweisen können!
      </p>
      <p>
        Während dies in unserem Beispiel natürlich ein legitimer Einwand ist,
        ist es in der Praxis nicht ganz so einfach. Einerseits wissen wir unter
        Umständen vorher nicht, welche Werte welcher Variablen wir tauschen
        wollen, andererseits wollen wir dies vielleicht auch noch mit einer
        großer Anzahl von Variablen tun. In beiden Fällen kommt der manuelle
        Ansatz jedenfalls schnell an seine Grenzen.
      </p>
      <p>
        Einem Beispiel dafür begegnen wir im Modul 'Sortieren mit System', wo
        wir mit einer potentiell beliebigen Anzahl an Variablen konfrontiert
        sind, von denen wir die Tauschreihenfolge vorher nicht kennen.
      </p>
    </div>
  </div>
</template>

<style scoped>
th,
td {
  border: 1px solid;
  text-align: center;
}

table {
  margin: 0 auto;
}

.table {
  width: 100%;
}

.attribute {
  border-bottom: 1px solid;
  border-bottom-width: 5px;
  border-bottom-style: double;
}

table {
  min-width: 500px;
  width: 70%;
}

.dots {
  font-weight: bolder;
  font-size: x-large;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
p {
  line-height: 1.3;
}
.delay-1 {
  animation-delay: 0.4s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 0.8s;
}
</style>
