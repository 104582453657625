<script setup>
  import { ref, defineEmits } from 'vue'
  import { onMounted } from 'vue'
  import VariableBox from '@/components/inf/variables/helpers/VariableBox.vue'
  import store from "@/store"
  
  const image = ref(require("@/assets/inf/variable/beri-image.png"))
  const form = ref({ name: '', type: '', val: ''})
  const current_ass = ref(0)
  const var_list = ref([])
  const relevant_vars = ref([{merkmal: "den Vornamen", typ: "Text", wert: "Beri"},
                           {merkmal: "den Nachnamen", typ: "Text", wert: "Taletovic"},
                           {merkmal: "das Geburtsdatum", typ: "Datum", wert: "1997-10-11"},
                           {merkmal: "die Postleitzahl des Wohnorts", typ: "Zahl", wert: 1040},
                           {merkmal: "den Namen des Wohnorts", typ: "Text", wert: "Wien"}])
  const errs = ref({empty: 0, duplicate: 0, invalid_name: 0, invalid_value: 0, wrong_type: 0, wrong_value: 0})
  const err_msg = ref('')

  const emit = defineEmits(['disableWeiter', 'enableWeiter'])

  onMounted(() => {
    console.log(`the component is now mounted.`)
    emit('disableWeiter')
    window.scrollTo(0, 0);
})

  function addVariable() {
    if(getFeedback()){
      var_list.value.push(form.value)
      clearInput()
      current_ass.value++
      if(current_ass.value == 5){
        emit('enableWeiter')
        logFinal(var_list.value)
        logErrors(errs.value)
      }
      }
    }
  function getFeedback(){
    if(form.value.name == ""){
      err_msg.value = "Geben Sie der Variable einen Namen."
      errs.value.empty++
      return false
    }
    else if(isDuplicate()){
      err_msg.value = "Dieser Name ist bereits belegt."
      errs.value.duplicate =+ 1
      return false
    }
    else if(isInvalidName()){
      err_msg.value = "Kein gültiger Name."
      errs.value.invalid_name++
      return false
    }
    else if(isInvalidValue()){
      err_msg.value = "Kein gültiger Wert."
      errs.value.invalid_value++
      return false
    }
    else if(relevant_vars.value[current_ass.value].typ != form.value.type){
      err_msg.value = "Falscher Variablentyp."
      errs.value.wrong_type++
      return false
    }
    else if(relevant_vars.value[current_ass.value].wert != form.value.val){
      err_msg.value = "Falscher Variablenwert."
      errs.value.wrong_value++
      return false
    }
    else{
      err_msg.value = ''
      return true
    }
  }
  function isDuplicate(){
    let isDuplicate = false
    var_list.value.forEach(
      function(vari) {
        if(vari.name === form.value.name)
          {isDuplicate = true}
      })
    return isDuplicate
  }
  function isInvalidName(){
    const name_regex = new RegExp("[a-zA-Z]{1,20}")
    return !name_regex.test(form.value.name)
  }
  function isInvalidValue(){
    let isInvalidValue = false
    if(form.value.type == "Zahl" && (form.value.val < 1 || form.value.val > 9999)){
      isInvalidValue = true
    }
    else if(form.value.type == "Datum"){
      const date = new Date(form.value.val).getDate()
      const start = new Date("1990-01-01").getDate()
      const end = new Date("2024-12-31").getDate()
      if(date < start || date > end){
        isInvalidValue = true
      }
    }
    return isInvalidValue
  }
  function clearInput(){
        form.value = {name: '', type: '', val: ''}
    }
  function logErrors(errors){
    store.commit('variablesA1LogErrors', JSON.parse(JSON.stringify(errors)))
  }
  function logFinal(vars){
    store.commit('variablesA1LogFinal', JSON.parse(JSON.stringify(vars)))
  }
</script>

<template>
  <div class = 'container'>
    <div class = 'row animate glow delay-1'>
      <h1>Aufgabe 1: Der Lebenslauf</h1>
      <p>
        Beri hat vor Kurzem ihr Informatikstudium an der Uni abgeschlossen und will 
        jetzt ihren Kurzlebenslauf bei einer Jobbörse einreichen. Was Sie nicht ahnt, 
        ist, dass diese Jobbörse es selbst nicht so mit Digitalisierung hat und es im 
        Hintergrund jemanden gibt, der die einzelnen Lebensläufe händisch in den 
        Computer eintippen muss. Nämlich Sie.
      </p>

      <p>
        In dieser Aufgabe sollen Sie Ihr 
        erworbenes Wissen zu Variablen anwenden, um die Daten des Lebenslaufs direkt als Variablen in unserem Sinne abspeichern.
      </p>

      <p>Sie sollten sich dabei genau überlegen, welche Daten mit welchem Typ 
         abgespeichert werden sollten. Da Sie nur drei Datentypen zur Verfügung 
         haben, sollten Sie die Datentypen so wählen, dass eine sinnvolle 
         Weiterverarbeitung der Daten ohne Weiteres möglich ist.</p>
      <p>Die Benennung der Variablen wird zur Gänze Ihnen überlassen, aber Sie sollten bedenken, dass es im Arbeitsalltag gute Gründe gibt, Variablen sinnvoll zu benennen. Gerade wenn man mit Hunderten davon am Stück zu tun haben könnte.</p>
    </div>
    <div class="row animate glow delay-2">
      <h2>Die Sprachnachricht</h2>
      <p>
        Beri hat Ihnen folgenden Kurzlebenslauf auf die Mobilbox gesprochen:
      </p>
    </div>
    <div class="row animate glow delay-3">
        <div class = "assignment-one">
            <div id = 'example'>
                <div id = 'image-box'>
                    <img :src= 'image' id = "alex-image" class = "animate glow delay-1"/>
                    <div class = 'bubble animate glow delay-2'>
                            <div class = "bubble-text">
                                <p>Hallo! Freut mich, Sie kennenzulernen!</p>
                                <p>Mein Name ist <span class = "essential vorname">Beri</span> <span class = "essential nachname">Taletovic</span>.</p> 
                                <p>Ich wurde am <span class = "essential plz">11.</span> <span class="essential month">Oktober</span> <span class="jahr">1997</span> geboren. </p>
                                <p>Zur Zeit wohne ich in <span class = "essential plz">1040</span> <span class = "essential wohnort"></span>Wien.</p>
                            </div>
                    </div>
                </div>
                <a href="https://www.flaticon.com/free-icons/teenager" title="teenager icons" class="credit-link" target="_blank">Teenager icons created by Freepik - Flaticon</a>
            </div>
        </div>
    </div>
    <p></p>
    <div class="row animate glow delay-3">
      <p v-if = "current_ass==5" class = "finish">Glückwunsch, Sie haben alle relevanten Daten in Variablen überführt!</p>
      <p v-if = "current_ass<5" class = "angabe">Speichern Sie {{ relevant_vars[current_ass].merkmal }} in eine Variable
.</p>
      <p v-if = "err_msg" class = "error-message">{{ err_msg }}</p>
      <div id = 'assignment-box'>
          <div id = 'form-box' v-if = "current_ass!=5">
            <div class = 'input-row'>
                <input  id = 'name-input' 
                        type = 'text' 
                        v-model = 'form.name' 
                        pattern="[a-zA-Z]{1,20}" 
                        placeholder="Name"
                        >
            </div>

            <div class = 'input-row'>
                <select id = 'type-input' 
                        v-model = 'form.type'>
                    <option value="" disabled selected hidden>Typ</option>
                    <option value = 'Text'>Text</option>
                    <option value = 'Zahl'>Zahl</option>
                    <option value = 'Datum'>Datum</option>
                </select>
            </div>

            <div class = 'input-row'>
                <div v-if = 'form.type == "Zahl"'>
                    <input id = 'value-input' 
                          type = 'number' 
                          v-model = 'form.val' 
                          min="1" max="9999"
                          placeholder="Wert"
                          >
                </div>
                <div v-if="form.type == 'Text'">
                    <input id = 'value-input' 
                          type = 'text' 
                          v-model = 'form.val' 
                          minlength="1" maxlength="10"
                          placeholder="Wert"
                          >
                </div>
                <div v-if="form.type == 'Datum'">
                    <input id = 'value-input' 
                          type = 'date' 
                          v-model = 'form.val' 
                          min="1990-01-01" max="2024-12-31"
                          placeholder="Wert"
                          >
                </div>
            </div>

            <button class = 'custom-btn' @click = 'addVariable()' :disabled="form.type==''">Variable
       hinzufügen</button>
        </div>
        <div id = 'variables'>
          <div v-for = 'item in var_list' :key = 'item.id'>
            <VariableBox
              :name = 'item.name'
              :type = 'item.type'
              :val = 'item.val' 
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.credit-link {
  font-size: 10px; /* or even smaller if you want */
  color: #555; /* Optional: change the color if you like */
  text-align: center; /* Optional: centers the link */
  display: block; /* Optional: places the link on its own line */
  margin-top: 10px; /* Optional: adds space above the link */
}
p{
  line-height: 1.3;
}
  #assignment-box {display: flex; align-items: center; width: 100%; border: 1px solid black; border-radius: 30px; overflow: hidden;}
  .input-row {display: flex; justify-content: space-between; padding: 5px 10px;}
  label {font-size: 12pt; font-weight: bold;}
  #form-box {border-right: 1px black solid; width: 200px; padding: 20px 10px;}
  #name-input,
  #type-input,
  #value-input {width: 160px; height:30px;}
  #variables {width: auto;  padding: 10px 30px; display: flex}
  .input-row {display: flex; justify-content: space-between; padding: 5px 10px;}
  .bubble-text {font-size: 40px;}
  .error-message {			
    width: auto;
      text-align: center;		
      border: 1px solid;
			margin: 0 auto;
			padding: 15px 50px 15px 50px;
			background-repeat: no-repeat;
			background-position: 10px center;
      color: #D8000C;
			background-color: #FFBABA;}
    .angabe {			
      width: auto;
      text-align: center;		
      border: 1px solid;
			margin: 0 auto;
			padding: 15px 50px 15px 50px;
			background-repeat: no-repeat;
			background-position: 10px center;
      color: #00529B;
			background-color: #BDE5F8;}
    .finish {			
      width: auto;
      text-align: center;		
      border: 1px solid;
			margin: 0 auto;
			padding: 15px 50px 15px 50px;
			background-repeat: no-repeat;
			background-position: 10px center;
      color: #4F8A10;
			background-color: #DFF2BF;}
  @keyframes shake {
      0% {
          margin-left: 0rem;
      }
      25% {
          margin-left: 0.5rem;
      }
      75% {
          margin-left: -0.5rem;
      }
      100% {
          margin-left: 0rem;
      }
  }
  #name-input:invalid,
  .value-input:invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.6rem #ff0000;
  background-color: pink
  }
  #type-input:invalid {
    color: grey;
  }
  .custom-btn:disabled {background-color: grey;}

  #example {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
#image-box {
  display: flex; justify-content: center; align-items: center;
}
#alex {
  text-align: right;
}
#alex-image {
  min-width:100px; width: 30%; height: auto; filter: drop-shadow(5px 5px 2px #A3A3A3);
}
#bubble-box {
  text-align: left;
  padding: 40px;
}
#example-text {
  font-weight: bold;
  text-align: center;
}
#text-box {
  width: 900px;
}
#variables-box {
  display: flex; flex-direction: row; gap: 20px;
}
#speech-text {
  font-size: 16pt;
}

.bubble {
    position: relative;
    background: #ffffff;
    color: #000000;
    font-family: Arial;
    font-size: 40px;
    filter: drop-shadow(5px 5px 2px #A3A3A3);
    text-align: center;
    width: 400px;
    height: 270px;
    border-radius: 10px;
    padding: 20px;
    border: #000000 solid 3px;
}
.bubble:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #ffffff;
    border-width: 20px 20px 20px 0;
    top: 50%;
    left: -20px;
    margin-top: -20px;
}
.bubble:before {
    content: '';
    position: absolute;
    width: 0;
    z-index: 0;
    border-style: solid;
    border-color: transparent #000000;
    border-width: 23px 23px 23px 0;
    top: 50%;
    left: -24.5px;
    margin-top: -23px;
    display: block;
}



* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arrow-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.the-arrow {
  width: 1px;
  transition: all 0.2s;
}
.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}
.the-arrow.-left > .shaft {
  width: 0;
  background-color: #4c4c4c;
}
.the-arrow.-left > .shaft:before, .the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #4c4c4c;
}
.the-arrow.-left > .shaft:before {
  transform: rotate(0);
}
.the-arrow.-left > .shaft:after {
  transform: rotate(0);
}
.the-arrow.-right {
  top: 3px;
}
.the-arrow.-right > .shaft {
  width: 1px;
  transition-delay: 0.2s;
}
.the-arrow.-right > .shaft:before, .the-arrow.-right > .shaft:after {
  width: 20px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}
.the-arrow.-right > .shaft:before {
  transform: rotate(70deg);
}
.the-arrow.-right > .shaft:after {
  transform: rotate(-70deg);
}
.the-arrow > .shaft {
  background-color: #4c4c4c;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}
.the-arrow > .shaft:before, .the-arrow > .shaft:after {
  background-color: #4c4c4c;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}
.the-arrow > .shaft:before {
  transform-origin: top right;
}
.the-arrow > .shaft:after {
  transform-origin: bottom right;
}

.animated-arrow {
  display: inline-block;
  color: #4c4c4c;
  font-size: 1.90em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
}
.animated-arrow:hover {
  color: #808080;
}
.animated-arrow:hover > .the-arrow.-left > .shaft {
  width: 64px;
  transition-delay: 0.1s;
  background-color: #808080;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before, .animated-arrow:hover > .the-arrow.-left > .shaft:after {
  width: 20px;
  transition-delay: 0.1s;
  background-color: #808080;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before {
  transform: rotate(40deg);
}
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  transform: rotate(-40deg);
}
.animated-arrow:hover > .main {
  transform: translateX(17px);
  transform: translateX(80px);
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft {
  width: 0;
  transform: translateX(200%);
  transition-delay: 0;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before, .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  width: 0;
  transition-delay: 0;
  transition: all 0.1s;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
  transform: rotate(0);
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  transform: rotate(0);
}
.animated-arrow > .main {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}
.animated-arrow > .main > .text {
  margin: 0 16px 0 0;
  line-height: 1;
}
.animated-arrow > .main > .the-arrow {
  position: relative;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.4s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 0.8s;
}
</style>
